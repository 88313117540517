<template>
  <section class="section header" id="header">
    <article class="header-info">
      <p>
        The details are not <br> the details, they make <br> the design.
        <span>/Charles Eames</span>
      </p>
    </article>
    <article class="header-titles">
      <img class="logo" src="@/assets/images/logo.svg" alt="logo">
    </article>
  </section>
  <div class="burbujas">
    <div class="burbuja"></div>
    <div class="burbuja"></div>
    <div class="burbuja"></div>
    <div class="burbuja"></div>
    <div class="burbuja"></div>
    <div class="burbuja"></div>
  </div>
</template>

<script>
// import logo from "@/assets/images/logo.svg";
export default {
  name: "HeaderComponent",
};
</script>
